import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc80d176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "user-header" }
const _hoisted_3 = {
  key: 0,
  class: "pr-2"
}
const _hoisted_4 = { class: "org-title" }
const _hoisted_5 = { class: "org-code" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "org-count-container"
}
const _hoisted_9 = { class: "org-count" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "p-3 user-title-card" }
const _hoisted_12 = {
  id: "user-title",
  class: "flex align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_TabsComponent = _resolveComponent("TabsComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return ($setup.loadingUser)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Loading..."))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["flex flex-column p-1", { 'overflow-hidden': !$setup.isMobile }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          ($setup.userOrgs && $setup.userOrgs.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "dropdown-org",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToOrg($setup.firstOrg))),
                  onMouseenter: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleOrgOn($event, $setup.userOrgs))),
                  onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($setup.delayedToggleForFirst($event)))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_mdicon, {
                      name: "domain",
                      class: "mr-1"
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      ($setup.firstOrg.organization)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($setup.firstOrg.organization.code), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.firstOrg.code), 1))
                    ]),
                    ($setup.userOrgs.length > 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString($setup.userOrgs.length), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ], 32)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_OverlayPanel, {
            ref: "showOrgsDropdown",
            class: "orgsOverlay",
            onMouseenter: $setup.keepOrgsOpen,
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => ($setup.toggleOrgOff($event)))
          }, {
            default: _withCtx(() => [
              ($setup.userOrgs)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.orgListWithoutFirst, (org) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "dropdown-org org-item",
                      onClick: ($event: any) => ($setup.goToOrg(org.organization))
                    }, [
                      _createVNode(_component_mdicon, {
                        name: "domain",
                        class: "mr-1"
                      }),
                      _createTextVNode(" " + _toDisplayString(org.organization.code), 1)
                    ], 8, _hoisted_10))
                  }), 256))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onMouseenter"]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h1", _hoisted_12, [
              _createVNode(_component_mdicon, {
                name: "account",
                class: "mr-2"
              }),
              _createTextVNode(" " + _toDisplayString($setup.user.username), 1)
            ])
          ])
        ]),
        _createVNode(_component_TabsComponent, { items: $setup.tabItems }, null, 8, ["items"]),
        _createVNode(_component_router_view, {
          user: $setup.user,
          onValueChanged: $setup.loadUser
        }, null, 8, ["user", "onValueChanged"])
      ], 2))
}