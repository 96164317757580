
import { useRoute, useRouter } from "vue-router"
import { computed, reactive, ref, watch } from "vue"
import UserService from "@/services/UserService"
import store from "@/store"
import useAppUtils from "@/utils/app"
import RolesService from "@/services/RolesService"
import { useStore } from "vuex"
import TabsComponent from "../navigation/TabsComponent.vue"

export default {
  setup() {
    const route = useRoute()
    const user: any = reactive({})
    const { isMobile } = useAppUtils()
    const userService = new UserService()
    //const userBodyKey = ref(0)
    const userId = computed(() => route.params.user_id as string)
    const tabItems = reactive([
      { label: "Profile", routeName: "userProfile" },
      { label: "Organizations", routeName: "userRoles" },
      { label: "Settings", routeName: "userSettings" },
    ])
    const loadingUser = ref(false)
    loadUser()
    function loadUser() {
      if (!userId.value) {
        return
      }
      loadingUser.value = true
      userService.getUser(userId.value).then((response) => {
        Object.assign(user, response.data)
        store.dispatch("setNewUserHistory", user)
        loadingUser.value = false
        //userBodyKey.value++
        loadUserOrgs()
      })
    }
    watch(
      () => userId.value,
      () => loadUser()
    )
    const router = useRouter()
    const rolesService = new RolesService()
    const store = useStore()
    const recentOrgs = store.getters.getLastOrgs
    const userOrgs = reactive([])
    const showOrgsDropdown = ref()
    const inDropdown = ref(false)
    const firstOrg = reactive({ id: "" })
    const orgListWithoutFirst = reactive([])
    function loadUserOrgs() {
      userOrgs.length = 0
      rolesService.getUserRoles(user.id, 0, "", "").then((result) => {
        ;[].push.apply(userOrgs, result.rows)
        setFirstOrg()
      })
    }
    function setFirstOrg() {
      let org =
        userOrgs.find((userOrg: any) =>
          recentOrgs.reverse().find((recOrg: any) => recOrg.id === userOrg.organization.id)
        )?.organization ?? userOrgs[0]
      Object.assign(firstOrg, org)
      cutOrgFromList()
    }
    function cutOrgFromList() {
      let list = userOrgs.filter((item: any) => item.organization.id !== firstOrg.id)
      ;[].push.apply(orgListWithoutFirst, list)
    }
    function toggleOrgOn(event: any, orgs: []) {
      if (orgs.length > 1) {
        inDropdown.value = false
        showOrgsDropdown.value.show(event)
      }
    }
    function toggleOrgOff(event: any) {
      showOrgsDropdown.value.hide(event)
      inDropdown.value = false
    }
    function keepOrgsOpen() {
      inDropdown.value = true
    }
    function delayedToggleForFirst(event: any) {
      setTimeout(() => {
        if (inDropdown.value === false) {
          showOrgsDropdown.value.hide(event)
        }
      }, 100)
    }
    function goToOrg(org: any) {
      if (org.organization) {
        router.push({ name: "organization", params: { organization_id: org.organization.id } })
      } else {
        router.push({ name: "organization", params: { organization_id: org.id } })
      }
    }
    return {
      tabItems,
      user,
      loadUser,
      //userBodyKey,
      loadingUser,
      isMobile,
      goToOrg,
      delayedToggleForFirst,
      toggleOrgOff,
      toggleOrgOn,
      showOrgsDropdown,
      keepOrgsOpen,
      cutOrgFromList,
      setFirstOrg,
      userOrgs,
      firstOrg,
      orgListWithoutFirst,
    }
  },
  components: { TabsComponent },
}
